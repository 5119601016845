

































































































































import Vue from 'vue'
import Chart from './Chart.vue'
import saveAs from 'file-saver'
import { getNowDateTime, stringifyDate } from '@/helpers/dates'
import Button from '@/components/ui/Button/Button.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import { WeatherStationData } from '@/store/weatherstation/definitions'
import { defaultBeginningSets, getDatasets } from './helper'

export default Vue.extend({
  name: 'WeatherStation',
  components: {
    Chart,
    'ui-button': Button,
    'ui-loader': Loader
  },
  props: {
    station: { default: () => ({}) },
    stationData: { default: () => ([]) },
    loading: {
      type: Boolean,
      default: false
    },
    displayWBGTAndHeatIndex: {
      type: Boolean,
      default: false
    },
    displayLightIntensity: {
      type: Boolean,
      default: false
    },
    detailUrl: {
      type: Object
    },
    dashboardUrl: {
      type: Object
    },
    displayDetail: {
      type: Boolean
    },
    displaySession: {
      type: Boolean,
      default: true
    },
    sessions: {
      default: () => ([])
    },
    selectedRangeLocal: {
      default: () => ([])
    },
    eventGeojson: {
      type: Object,
      required: false
    },
    trackOrWbgt: {
      type: String,
      default: 'track'
    }
  },
  data: () => ({
    datasetTemperature: null,
    datasetWind: null,
    datasetHuPressure: null,
    datasetRain: null,
    datasetWindDirection: {
      x: [],
      y: []
    },
    datasetWindRoseDirection: null,
    minTemperature: null,
    maxTemperature: null,
    minWind: null,
    maxWind: null,
    minHumidity: null,
    maxHumidity: null,
    minPressure: null,
    maxPressure: null,
    maxRain: null,
    rainIntensityTotal: 0,
    pressureAddition: 5,
    windAddition: 5,
    temperatureAddition: 5,
    humidityAddition: 1
  }),
  computed: {
    restrictDatasetTemperature () {
      if (!this.datasetTemperature) return
      /**
       * We create a dataset depending the trackOrWbgt
       *
       * Indeed, in the datasetTemperature, we hold the air, ttrack, wbgt and heatindex series
       *
       * Depending trackOrWbgt, we'll return the track (number 1) or wbgt (number 2) serie
       */
      return [
        this.datasetTemperature[0],
        this.datasetTemperature[this.trackOrWbgt === 'track' ? 1 : 2],
        this.datasetTemperature[4],
        this.datasetTemperature[this.trackOrWbgt === 'track' ? 5 : 6]
      ]
    },
    reversedStationData () {
      if (!this.stationData) return []
      return this.stationData.slice().sort((a, b) => {
        if (a.time > b.time) return -1
        return 1
      })
    }
  },
  methods: {
    exportAsCSV () {
      const csvData = []
      csvData.push(
        'Date Time,' +
        'Time,' +
        'Air Temperature,' +
        (
          this.trackOrWbgt === 'track'
            ? 'Track Temperature,'
            : 'WBGT Temperature,'
        ) +
        (
          this.displayWBGTAndHeatIndex ? 'WBGT,' +
        'Heat Index,' : ''
        ) +
        'Humidity,' +
        'Pressure,' +
        'Wind Direction,' +
        'Wind Average Speed,' +
        'Gusts,' +
        (
          this.displayLightIntensity ? 'Light Intensity,' : ''
        ) +
        'Rain\n'
      )
      this.stationData.forEach(currentData => {
        csvData.push(
          currentData.timeFormatted + ',' +
          currentData.timeToDisplay + ',' +
          currentData.airTemperature + ',' +
          (
            this.trackOrWbgt === 'track'
              ? currentData.trackTemperature + ','
              : currentData.wbgtTemperature + ','
          ) +
          (
            this.displayWBGTAndHeatIndex ? currentData.wbgtTemperature + ',' +
          currentData.heatIndex + ',' : ''
          ) +
          currentData.humidity + ',' +
          currentData.pressure + ',' +
          currentData.windDirectionDegree + ',' +
          currentData.windSpeed + ',' +
          currentData.gusts + ',' +
          (
            this.displayLightIntensity ? currentData.lightIntensity + ',' : ''
          ) +
          currentData.rainIntensity + '\n'
        )
      })
      const blob = new Blob(csvData, { type: 'text/plain;charset=utf-8' })
      const now = stringifyDate(getNowDateTime())
      saveAs(blob, now + '_weatherstation_' + this.station.properties.reference + '.csv')
    },
    resetData () {
      Object.keys(defaultBeginningSets).forEach(key => {
        this[key] = defaultBeginningSets[key]()
      })
    }
  },
  watch: {
    stationData: {
      immediate: true,
      handler (newData: WeatherStationData[]) {
        const datasets = getDatasets(newData, this.trackOrWbgt)
        datasets && Object.keys(datasets).forEach(key => {
          this[key] = datasets[key]
        })
      }
    }
  }
})
